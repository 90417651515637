import React from "react";
import { useNavigate } from 'react-router-dom';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import InstallPrompt from '../components/InstallPrompt';

import Twemoji from 'react-twemoji';

import 'swiper/swiper.min.css'
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

const Tips = () => {

  const navigate = useNavigate();

  const handleOnboardingClick = () => {
    navigate('/login');
  };

  return (
    <div className="tipsWrapper">
      <InstallPrompt/>
      <Swiper
        className="Swiper"
        modules={[Pagination]}
        spaceBetween={50}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        <SwiperSlide className="SwiperSlide">
          <Twemoji options={{ className: 'emoji', folder: "svg", ext: ".svg" }}>
            <span>💰</span>
          </Twemoji>
            <h1>(Be)spaar</h1>
            <h2>Verbind Kasza met je bank & krijg tips om goede financiële gewoontes te beginnen, te besparen op abonnementen, en nog veel meer.</h2>
        </SwiperSlide>
        <SwiperSlide className="SwiperSlide">
          <Twemoji options={{ className: 'emoji', folder: "svg", ext: ".svg" }}>
            <span>🤖</span>
          </Twemoji>
            <h1>100% gepersonaliseerd</h1>
            <h2>Met onze AI geven we je gepersonaliseerde tips &amp; bedragen zodat je meteen weet waar je voor staat.</h2>
        </SwiperSlide>
        <SwiperSlide className="SwiperSlide">
          <Twemoji options={{ className: 'emoji', folder: "svg", ext: ".svg" }}>
            <span>💡</span>
          </Twemoji>
            <h1>1 Tip per week</h1>
            <h2>Wij gaan voor efficiëntie! Slechts 1x per week onze tips volgen is mogelijks 100en euros (be)spaard.</h2>
        </SwiperSlide>
        <SwiperSlide className="SwiperSlide">
          <Twemoji options={{ className: 'emoji', folder: "svg", ext: ".svg" }}>
            <span>🌳</span>
          </Twemoji>
            <h1>Gratis voor altijd</h1>
            <h2>Onze gratis versie zal je altijd kunnen blijven gebruiken, al mis je mogelijks wat tips</h2>
        </SwiperSlide>
        <SwiperSlide className="SwiperSlide">
          <Twemoji options={{ className: 'emoji', folder: "svg", ext: ".svg" }}>
            <span>🚀</span>
          </Twemoji>
            <h1>Premium!</h1>
            <h2>(Be)spaar je niet minstens het dubbele van je subscriptie per jaar? Geld terug!</h2>
            <br/>
            <br/>
            <span className="subtext">Al vanaf 2 euro per maand</span>
            <br/>
            <br/>
            <br/>
            <br/>

            <button className="buttonOrange" onClick={handleOnboardingClick}>Begrepen!</button>
        </SwiperSlide>
        <div onClick={handleOnboardingClick} className="skip">
          Skip
        </div>
      </Swiper>
    </div>
  );
};

export default Tips;
