import { BrowserRouter as Router, Route, Outlet, Routes } from 'react-router-dom';
import Loading from "./components/Loading";
import Tips from "./components/tips";
import Auth from "./components/auth";
import Linked from "./components/linked";
import PricingPage from "./components/stripeCheckout";
import { useUserContext } from "./context/userContext";
import BackButton from "./components/BackButton";
import DashboardStart from "./components/DashboardStart";
import DashboardWarning from "./components/DashboardWarning";
import DashboardPicker from "./components/DashboardPicker";
import ImprovementsWrapper from "./components/improvements/ImprovementsWrapper";
import ImprovementsOverview from "./components/improvements/ImprovementsOverview";

function App() {
  const { error } = useUserContext();
  
  const PrivateRoute = (props) => {
    const { user, loading } = useUserContext();

    if (loading) {
      return <Loading />;
    }

    if (!user) {
      return <Auth />;
    }

    return <Outlet />;
  };

  return (
    <div className="App">
      <div className="container">
        <Router>
          <BackButton />
          <img className="logo" alt="Kasza logo" src="../logo.png" />
          {error ? <div className="error">{error}</div> : ""}
          <Routes>
            <Route path="/login" element={<Auth />} />
            <Route path="/dashboard" element={<PrivateRoute />}>
              <Route index element={<DashboardStart />} />
              <Route path="warning" element={<DashboardWarning />} />
              <Route path="picker" element={<DashboardPicker />} />
            </Route>
            <Route path="/linked" element={<PrivateRoute />}>
              <Route index element={<Linked />} />
            </Route>
            <Route path="/premium" element={<PrivateRoute />}>
              <Route index element={<PricingPage />} />
            </Route>
            <Route path="/improvements" element={<PrivateRoute />}>
              <Route index element={<ImprovementsOverview />} />
              <Route path=":id" element={<ImprovementsWrapper />} />
            </Route>
            <Route path="/" element={<Tips />} />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
