import React, { useState } from "react";
import { useUserContext } from "../context/userContext";
import { useNavigate } from 'react-router-dom';
import Signin from "./signin";
import Signup from "./signup";


const Auth = () => {
  const [index, setIndex] = useState(false);
  const toggleIndex = () => {
    setIndex((prevState) => !prevState);
  };
  const navigate = useNavigate();

  const { user,signInWithGoogle } = useUserContext();

  if(user){
    navigate('/dashboard');
  }
  
  return (
    <div>
      <h3 onClick={toggleIndex}>
        {!index ? <div><span className="bubble">Login</span> / Nieuw hier?</div> : <div>Login / <span className="bubble">Welkom!</span></div> }
      </h3>
      {!index ? <Signin /> : <Signup />}
      <div className="socialLoginContainer">
        <button className="buttonIcon" onClick={signInWithGoogle}><img alt="google login button with logo" src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"/></button>
        <button className="buttonIcon disabled" disabled><img alt="github login button with logo" src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/91/Octicons-mark-github.svg/1200px-Octicons-mark-github.svg.png?20180806170715"/></button>
        <button className="buttonIcon disabled" disabled><img alt="facebook login button with logo" src="https://upload.wikimedia.org/wikipedia/en/thumb/0/04/Facebook_f_logo_%282021%29.svg/512px-Facebook_f_logo_%282021%29.svg.png?20210818083032"/></button>
      </div>
    </div>
  );
};

export default Auth;
